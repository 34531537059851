import regeneratorRuntime from "regenerator-runtime";
import { h, app } from "hyperapp";
import { Link, Route, location, Switch } from "@hyperapp/router";
import axios from 'axios';
const { utils, Wallet } = require('ethers');
const { sendTransaction, balanceOf, call, Eth, onReceipt } = require('ethjs-extras');
const scryptAsync = require("scrypt-async");
const IPFS = require('ipfs-mini');
const { randomBytes, secretbox } = require('tweetnacl');

// local packages..
const styled = require('./lib/styled-elements').default;
const passwords = require('./passwords');

// define initial app state
const state = {
  location: location.state,
  transaction: {},
  account: null,
  error: null,
  type: 'dai',
  etherUSDPrice: 130,
  fundsAvaiable: false,
};

// define initial actions
const actions = {
  location: location.actions,
  change: obj => obj,
};

// no operation
const noop = () => {};

// localmemory storage
let localMemory = {};

// localstorage
const local = window.localStorage || {
  setItem: (key, value) => Object.assign(localMemory, { [key]: value }),
  getItem: key => localMemory[key] || null,
};

// provider
let provider = window.ethereum || (window.web3 || {}).currentProvider;

// provider..
const eth = Eth({ provider });

// server url
const serverURL = 'https://api.nickpay.com';

// json params for axios
const post = (url, data) => axios.post(serverURL + url, JSON.stringify(data));

// null token address
const nullAddress = '0x0000000000000000000000000000000000000000';

// dai token address
const daiTokenAddress = '0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359';

// nickpay contract
const nickpayAddress = '0xdacce757c5fc1df946ead943353cf9e3b69054e3';

// shorthand
const keccak256 = utils.keccak256;
const encodePacked = utils.solidityPack;

// lower case it
const lower = v => String(v).toLowerCase();

// setup IPFS module
const ipfs = new IPFS({ host: 'ipfs.infura.io', port: 5001, protocol: 'https' });

// get the dai balane
const daiBalanceOf = async address => utils.bigNumberify((await call({
  provider,
  address: daiTokenAddress,
  args: [address],
  solidity: 'balanceOf(address):(uint256)',
}))[0]);

// get the allowance of a spender from dai
const daiAllowanceOf = async (from, spender, block) => utils.bigNumberify((await call({
  provider,
  address: daiTokenAddress,
  args: [from, spender],
  solidity: 'allowance(address,address):(uint256)',
  block: block || 'latest',
}))[0]);

// sign typed data with given provider
const signTypedData = (provider, typedData, from) => new Promise((resolve, reject) => provider
  .sendAsync({
    method: 'eth_signTypedData',
    params: [typedData, from],
    from,
  }, (error, result) => {
    if (error || !result) return reject(error);
    resolve(result.result);
  }));

// scrypt memory hashing for simple passwords promisified
const scrypt = (password, salt) => new Promise((resolve, reject) => {
  try {
    scryptAsync(password, salt, {
      N: (1 << 17), // about 6 seconds per try..
      r: 8,
      p: 1,
      dkLen: 32,
      encoding: 'hex'
    }, hash => resolve(`0x${hash}`));
  } catch (error) { reject(error); } // handle error
});

// lock the box (string, string, string) => lockbox hex string
const naclLock = (passwordHex, data) => {
  // create a key from the password hash
  const key = utils.arrayify(passwordHex); // array it

  // create a nonce
  const nonce = randomBytes(secretbox.nonceLength);

  // create a box
  const box = secretbox(utils.toUtf8Bytes(data), nonce, key);

  // box plus nonce
  return utils.hexlify(utils.concat([nonce, box]));
};

// unlock the box (hex, hex) => unecnrypted data..
const naclUnlock = (passwordHex, lockBoxHex) => {
  // create a key from the password hash
  const key = utils.arrayify(passwordHex); // array it

  // box as array
  const lockBoxArray = utils.arrayify(lockBoxHex);

  // create a nonce
  const nonce = lockBoxArray.slice(0, secretbox.nonceLength);

  // box
  const box = lockBoxArray.slice(secretbox.nonceLength, lockBoxArray.length);

  // create a box
  return utils.toUtf8String(secretbox.open(box, nonce, key));
};

// are you sure message for unload.
window.onbeforeunload = function(e) {
  return 'Are you sure you want to close this tab. Data could be lost!';
};

// detect window.ethereum
actions.detect = () => (state, actions) => {
  // attempt to get provider
  const detectedProvider = window.ethereum || (window.web3 || {}).currentProvider;

  // check if provider is available
  if (!detectedProvider) return setTimeout(actions.detect, 5000);

  // change account in state
  actions.change({ ethereum: provider ? true : false });

  // set provider object
  provider = detectedProvider;
};

// on application load
actions.load = () => async (state, actions) => {
  try {
    // detectionInterval
    actions.detect();

    // get ether price
    // const priceData = ((await axios.get('https://api.coinmarketcap.com/v1/ticker/ethereum/')) || {}).data || {};
    const etherUSDPrice = 130; // parseFloat(priceData[0].price_usd || 130);

    // detect is ios
    const ios = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    // is mobile
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    // is firefox
    const firefox = /fire|firefox|Firefox/i.test(navigator.userAgent);

    // set ether pricesaxios
    actions.change({ etherUSDPrice, ios, mobile, firefox });
  } catch (error) {
    console.log(error);

    actions.change({ error: ((error.response || {}).data || {}).error || error.message });
  }
};

// get transaciton data from nickpay
actions.getTransaction = params => async (state, actions) => {
  try {
    // const raw = await post('/pickup', params);
    // const transaction = (((raw || {}).data || {}).result || {});
    const encryptedBlob = await ipfs.cat(params.ipfsHash);

    // hash secret
    const secretHexHash = keccak256(params.secret);

    // transaction data
    const transaction = JSON.parse(naclUnlock(secretHexHash, encryptedBlob));

    // get proxy information
    const etherBalance = transaction.value;

    // dai balance
    const daiBalance = transaction.value;

    // dai balance
    const daiAvailable = transaction.type === 'dai';

    // funds available
    const fundsAvaiable = etherBalance !== '0' || daiBalance !== '0';

    // setup contract..
    actions.change({ transaction, etherBalance, daiBalance, fundsAvaiable, daiAvailable });
  } catch (error) {
    actions.change({ error: ((error.response || {}).data || {}).error || error.message });
  }
};

// actions load account
actions.account = () => async (state, actions) => {
  try {
    // enable if available
    ((window.ethereum || {}).enable || noop)();

    // get main account from provider
    const account = ((await Eth({ provider }).raw('eth_accounts')) || [])[0];

    // if no account available, try again..
    if (!account) {
      setTimeout(actions.account, 1000);

      return null;
    }

    // change account in state
    actions.change({ account });

    // return account
    return account;
  } catch (error) {
    console.log(error);
  }
};

// actions pickup eth
actions.pickup = params => async (state, actions) => {
  try {
    // prepair password
    const password = lower(document.getElementById('password').value);

    // get account
    const account = await actions.account();

    // hash password
    const passwordHash = await scrypt(password, state.transaction.signingAddress);

    // destination pulled from injected provider..
    const destination = account;

    // attempt key decrypt
    let signingKeyDecrypted = null;

    // handle decryption of secret key
    try {
      signingKeyDecrypted = naclUnlock(passwordHash, state.transaction.signingPrivateKey);
    } catch (error) {
      throw new Error('The password provided is incorrect, please try again.');
    }

    // value pulled from tx data
    const value = state.transaction.value;

    // expiry shorthand
    const expiry = state.transaction.expiry;

    // invalid password..
    if (String(signingKeyDecrypted).slice(0, 2) !== '0x') throw new Error('Invalid password, try again!');

    // wallet using private key from service.. to eventually be encrypted!!
    const wallet = new Wallet(signingKeyDecrypted);
    const signingKey = new utils.SigningKey(signingKeyDecrypted);

    // check signing key address
    if (lower(wallet.address) !== lower(state.transaction.signingAddress)) throw new Error('Invalid password, try again!');

    // destination as bytes32
    const destinationBytes32 = utils.hexZeroPad(destination, 32);

    // completed signed digest..
    const signedDigest = signingKey.signDigest(destinationBytes32);

    // token address
    const token = state.daiAvailable ? daiTokenAddress : nullAddress;

    // amount
    const amount = state.transaction.value;

    // fee amount
    const fee = utils.bigNumberify(String(state.transaction.fee));

    // release hash computation eth_signTypedData VERSION 1
    const types = ['address sender', 'uint256 expiry', 'address token', 'uint256 amount', 'uint256 fee'];
    const values = [
      state.transaction.signingAddress,
      expiry,
      token,
      amount,
      fee.toString(10),
    ];
    const typeHash = keccak256(encodePacked(['string', 'string', 'string', 'string', 'string'], types));
    const valueHash = keccak256(encodePacked(['address', 'uint256', 'address', 'uint256', 'uint256'], values));
    const releaseHash = keccak256(encodePacked(['bytes32', 'bytes32'], [typeHash, valueHash]));

    // check token owner information against provided signature
    const recoveredAddress = utils.recoverAddress(releaseHash, state.transaction.signature);

    // parse and split creator signature
    const creatorDigest = utils.splitSignature(state.transaction.signature);

    // check digest against token owner.
    if (lower(state.transaction.from) !== lower(recoveredAddress)) throw new Error('Invalid token owner or invalid release hash construction!');

    // if there is a fee included check transaction deployment with server
    if (fee.gt(utils.bigNumberify('0'))) {
      const pickupData = await post('/pickup', {
        senderSignature: utils.joinSignature(signedDigest),
        creatorSignature: state.transaction.signature,
        expiry: state.transaction.expiry,
        token: state.transaction.type === 'dai' ? daiTokenAddress : nullAddress,
        destination: destination,
        amount: state.transaction.value,
        fee: state.transaction.fee,
      });

      // is either true / false or transacitonHash
      const resultHashOrBool = (pickupData.data || {}).result;

      // tran
      const transactionHash = String(resultHashOrBool).slice(0, 2) === '0x' ? resultHashOrBool : null;

      // message for user
      actions.change({ result: 'Huzzah! Your pickup transaction is being processed!', transactionHash, error: null });
    } else {
      // final transaction hash
      let transactionHash = null;

      // get gas price
      const prices = ((await axios.get('https://ethgasstation.info/json/ethgasAPI.json')) || {}).data || {};
      const safeLow = ((parseInt(prices.safeLow, 10) + 10) * 100000000) || '2000000000';

      // message for user
      actions.change({ result: 'Awaiting wallet approval to unlock your funds.', error: null });

      // build transaction
      transactionHash = await sendTransaction({
        provider,
        address: nickpayAddress,
        from: account,
        gasPrice: safeLow,
        gasLimit: '4000000',
        solidity: 'pickup(uint8 v, bytes32 r, bytes32 s, uint8 v2, bytes32 r2, bytes32 s2, uint256 expiry, address token, bytes32 destination, uint256 amount, uint256 fee):(address recipient)',
        args: [signedDigest.v, signedDigest.r, signedDigest.s, creatorDigest.v, creatorDigest.r, creatorDigest.s, expiry, token, destinationBytes32, amount, fee],
      });

      // message for user
      actions.change({ result: 'Unlocking funds, this may take several minutes..', transactionHash, error: null });

      // get receipt
      const receipt = await onReceipt(transactionHash, { provider });

      // Check receipt status
      if (receipt.status !== '0x1') throw new Error('Darn! There was a problem with your unlocking transaction..');

      // message for user
      actions.change({ result: 'Huzzzzah! Funds successfully deposited!', success: true, transactionHash, error: null });
    }
  } catch (error) {
    actions.change({ error: ((error.response || {}).data || {}).error || error.message });
  }
};

const Container = styled.div`
  padding: 20%;
  padding-top: 50px;

  @media only screen and (max-width:640px) {
    padding: 3%;
  }
`;

// install browser widget
const InstallButton = styled.a`
  display: inline-flex;
  border-radius: 5px;
  padding: 20px;
  border: 1px solid lightgray;
  color: black;
  text-decoration: none;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 20px;
`;

// install browser icons
const InstallBrowsers = () => state => (<div>
  {state.mobile ? (<div style="display: inline-block">
      <InstallButton
        target="_blank"
        href={state.ios ? 'https://itunes.apple.com/us/app/cipher-browser-ethereum/id1294572970?mt=8' : 'https://play.google.com/store/apps/details?id=com.cipherbrowser.cipher&hl=en'}>
        <img width="100" height="100" src="https://pbs.twimg.com/profile_images/937807890391248896/tCQFdTBF.jpg" />
        <div style="margin-left: 20px">Cipher Browser</div>
      </InstallButton>

      {!state.ios ? (<InstallButton
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.opera.browser">
        <img width="100" height="100" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Opera_2015_icon.svg/220px-Opera_2015_icon.svg.png" />
        <div style="margin-left: 20px">Opera Browser</div>
      </InstallButton>) :''}

      <InstallButton
        target="_blank"
        href={state.ios ? 'https://itunes.apple.com/us/app/trust-ethereum-wallet/id1288339409' : 'https://play.google.com/store/apps/details?id=com.opera.browser'}>
        <img width="100" height="100" src="https://avatars0.githubusercontent.com/u/32179889?s=200&v=4" />
        <div style="margin-left: 20px">Trust Wallet</div>
      </InstallButton>
    </div>) : (<div style="display: inline-block">
      <InstallButton
        target="_blank"
        href={state.firefox ? 'https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/' : 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'}>
        <img width="100" height="100" src="https://cdn.worldvectorlogo.com/logos/metamask.svg" />
        <div style="margin-left: 20px">MetaMask Extension <br /> (recommended)</div>
      </InstallButton>

      <InstallButton
        target="_blank"
        href={state.ios ? 'https://itunes.apple.com/us/app/brave-browser-fast-adblocker/id1052879175?mt=8' : 'https://brave.com/download/'}>
        <img width="100" height="100" src="https://images-na.ssl-images-amazon.com/images/I/51IbhWne1lL.png" />
        <div style="margin-left: 20px">Brave Browser</div>
      </InstallButton>
    </div>)}
</div>);

// display address
const displayAddress = address => address ? `${address.slice(0, 6)}...${address.slice(-4)}` : '';

// pickup container used to get the ether to recipient
const Pickup = ({ match }) => (state, actions) => {
  const urlData = (match.params || {});

  // date format
  const expiryDate = new Date(parseInt(state.transaction.expiry, 10) * 1000);
  const expiryFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const expiryFormated = expiryDate.toLocaleDateString("en-US", expiryFormatOptions);

  return (
    <Container oncreate={e => actions.getTransaction(urlData)}>
      <h1>NickPay</h1>
      <p>Deposit your funds by entering the correct password below.</p>

      <br />

      <h3 style={!state.transaction.value ? '' : 'display: none;'}>
        Loading fund information...
      </h3>

      <div style={!state.transaction.value ? 'display: none;' : ''}>

        <h4>Expires:</h4>
        <p>{expiryFormated}</p>

        <h4>Amount:</h4>
        <p>{state.transaction.value ? (state.daiAvailable
          ? `${utils.formatEther(state.daiBalance)} DAI (USD)`
          : `${utils.formatEther(state.transaction.value)} ether`) : 'Loading...'}</p>

        {state.transaction.value && !state.fundsAvaiable ? (
          <h3 style="margin-top: 40px;">This contract has already been unlocked and emptied.</h3>
        ) : ''}

        <div style={!state.fundsAvaiable ? 'display: none;' : ''}>

          {state.ethereum ? (!state.result ? (
            <div>
              <h4>Whats the password?</h4>
              <input style={{ padding: '20px' }}
                oncreate={element => setTimeout(() => element.focus(), 100)}
                id="password" type="password" placeholder="Password" />

              <br /><br />

              <button style={{ padding: '20px' }} onclick={e => actions.pickup()}>
                Deposit Funds
              </button>
            </div>
          ) : '') : (
            <div>
              <br />

              <h3>Setup a Wallet</h3>
              <p>This page requires an Ethereum enabled browser.

              <br /> <br /> <b>Please install one of the following options and refresh the page:</b></p>

              <br />

              <InstallBrowsers />
            </div>
          )}
        </div>

        {state.result ? (<div>
          <h3>{state.result || ''}</h3>

          <p>This may take several minutes to process, please be patient..</p>

          <br /><br />

          <p>{state.transactionHash ? (
            <a href={`https://etherscan.io/tx/${state.transactionHash}`} target="_blank">
              View Confirmation Receipt on Etherscan
            </a>
          ) : ''}</p>

          {state.success && state.daiAvailable ? (<small>
            <b>Note: </b> If you don't see your Dai in your wallet, check under the Menu tab (top left).
          </small>) : ''}
        </div>) : ''}

        <br />

        {state.error ? (<div>
          <h2>Hmm, something went wrong: </h2>
          <p>{state.error}</p>
        </div>) : ''}
      </div>
    </Container>
  );
}

// dropooff your eth in nickpay
actions.dropoff = () => async (state, actions) => {
  try {
    // raw data form inputs
    const email = document.getElementById('email').value;
    const amount = document.getElementById('amount').value;
    const amountParsed = utils.parseEther(amount == '' ? '0' : amount);
    const message = document.getElementById('message').value;
    const password = lower(document.getElementById('password').value);
    const repassword = lower(document.getElementById('repassword').value);
    const paygas = document.getElementById('paygas').checked;

    // get account
    const account = await actions.account();

    // handle invalid values
    if (email.length <= 0) throw new Error('You must specify an email address');
    if (amountParsed.eq('0')) throw new Error('You must specify an amount greater than zero!');
    if (password.length < 8) throw new Error('Your password must be longer than 7 characters.');
    if (password !== repassword) throw new Error('Your passwords do not match!');
    if (passwords.split("\n").indexOf(password) !== -1) throw new Error("Please dont use easy passwords...");
    if (lower(message).indexOf(password) !== -1) throw new Error("Please don't include the password in the message...");

    // check dai balance
    const daiBalance = await daiBalanceOf(account);

    // dai selected
    const daiSelected = state.type === 'dai';

    // check dai balance..
    if (daiSelected && daiBalance.lt(amountParsed))
      throw new Error('You do not have enough Dai to send this transaction');

    // Create a wallet to sign the hash with
    const wallet = new Wallet.createRandom();
    const signingKey = new utils.SigningKey(wallet.privateKey);

    // get gas price
    const prices = ((await axios.get('https://ethgasstation.info/json/ethgasAPI.json')) || {}).data || {};
    const safeLow = ((parseInt(prices.safeLow, 10) + 5) * 100000000) || '2000000000';

     // UTC 1 week from today
    const expiry = String(Math.floor(new Date().getTime() / 1000.0)  + 604800);

    // general cost cost
    const pickupGasUsed = utils.bigNumberify('181436');

    // eth gas fee
    const etherFee = pickupGasUsed.mul(utils.bigNumberify(String(safeLow)));

    // fee amount
    const fee = daiSelected ? utils.parseEther('0.04') : etherFee;

    // message params
    const typedData = [
      {
        type: 'address',
        name: 'sender',
        value: wallet.address,
      },
      {
        type: 'uint256',
        name: 'expiry',
        value: expiry,
      },
      {
        type: 'address',
        name: 'token',
        value: daiSelected ? daiTokenAddress : nullAddress,
      },
      {
        type: 'uint256',
        name: 'amount',
        value: amountParsed.toString(10),
      },
      {
        type: 'uint256',
        name: 'fee',
        value: fee.toString(10),
      },
    ];

    // awaiting signature
    actions.change({ result: 'Step 1 of 2: Awaiting your signature, check your wallet for the request.', error: null });

    // attempt sendAsync here..
    const signature = await signTypedData(provider, typedData, account);

    // if no signature, throw error
    if (!signature) throw new Error('You have declined signing this transaction.');

    // release hash computation eth_signTypedData VERSION 1
    const types = ['address sender', 'uint256 expiry', 'address token', 'uint256 amount', 'uint256 fee'];
    const values = typedData.map(v => v.value);
    const typeHash = keccak256(encodePacked(['string', 'string', 'string', 'string', 'string'], types));
    const valueHash = keccak256(encodePacked(['address', 'uint256', 'address', 'uint256', 'uint256'], values));
    const releaseHash = keccak256(encodePacked(['bytes32', 'bytes32'], [typeHash, valueHash]));

    // check token owner information against provided signature
    const recoveredAddress = utils.recoverAddress(releaseHash, signature);

    // awaiting signature
    actions.change({ result: 'Step 2 of 2: Awaiting token transfer approval, check your wallet for the request!', error: null });

    // if dai transaction
    const defaultTransactionObject = {
      provider,
      gasLimit: '4000000',
      gasPrice: safeLow,
    };

    // check if there is another tx pending
    const localPendingTransactionHash = local.getItem('transactionPending');

    // check on this tx
    if (localPendingTransactionHash && localPendingTransactionHash !== 'null') {
      // get pending tx
      const pendingReceipt = await eth.raw('eth_getTransactionReceipt', localPendingTransactionHash);

      // you cannot send if you have another tx pending..
      if (!pendingReceipt) throw new Error('You have a transaction pending..');
    }

    // get what is previously approved to NickPay
    const nickpayAllowance = await daiAllowanceOf(account, nickpayAddress);

    // zero BN
    const zeroBN = utils.bigNumberify('0');

    // new allowance
    const allowance = (daiSelected ? nickpayAllowance : zeroBN).add(amountParsed).add(fee).toString(10);

    // build transaction object
    const transactionObject = daiSelected ? {
      address: daiTokenAddress,
      from: account,
      value: '0',
      solidity: 'approve(address spender, uint256 tokens):(bool success)',
      args: [nickpayAddress, allowance],
    } : {
      address: nickpayAddress,
      from: account,
      value: allowance.toString(10),
      solidity: 'deposit()',
      args: [],
    };

    // transaction hash
    let transactionHash = null;

    // send transaction
    try {
      // use build payload and send transaction
      transactionHash = await sendTransaction(Object.assign(defaultTransactionObject, transactionObject));
    } catch (error) {
      throw new Error('Looks like you have declined the transaction.');
    }

    // set transaction pending
    local.setItem(`transactionPending`, transactionHash);

    // resulting success message
    actions.change({ result: 'Your funds are being transfered -- WARNING: DO NOT CLOSE THIS TAB OR YOUR BROWSER.', transactionHash, error: null });

    // get receipt
    const receipt = await onReceipt(transactionHash, { provider }); // wait for receipt to process..

    // set transaction pending
    local.setItem(`transactionPending`, null);

    // check receipt status
    if (receipt.status !== '0x1') throw new Error('Your transaction has failed.');

    // setup approval interface
    const inter = new utils.Interface([
      'event Approval(address indexed tokenOwner, address indexed spender, uint tokens)'
    ]);

    // password scrypt
    const passwordHashHex = await scrypt(password, wallet.address);

    // notify nickpay of the transaction
    const raw = await post('/notify', {
      transactionHash,
      encryptedSigningKey: naclLock(passwordHashHex, wallet.privateKey),
      signingAddress: wallet.address,
      signature,
      expiry,
      email,
      message,
      amount: amountParsed.toString(10),
      fee: fee.toString(10),
    });

    // resulting success message
    actions.change({ result: `Hurray! ${daiSelected ? '$' : ''}${utils.formatEther(amountParsed)} ${daiSelected ? 'USD (Dai)' : 'Ether'} has been sent to ${email}`, error: null });
  } catch (error) {
    actions.change({ error: ((error.response || {}).data || {}).error || error.message });
  }
};

// on type change
actions.ontype = e => (state, actions) => {
  // get type value from select box
  const type = e.target.value;

  // change type
  actions.change({ type });
};

const Dropoff = ({ match }) => (state, actions) => {
  const urlData = (match.params || {});

  return (
    <Container>
      <h1 style="margin-bottom: 40px;">NickPay</h1>
      - Send Ether or Dai via email<br />
      - Your funds are password encrypted and timelocked to 7 days <br />
      - <b>NickPay never has access to, holds or controls your funds</b><br />
      - Retrieve your funds at anytime with original sender account <br />

      <br />

      <h3>How does it work?</h3>
      <div style="display: flex; flex-direction: row;">
        <b>1. Password lock your crypto</b>
        <b style="margin-left: 20px;">2. Recipient receives email</b>
        <b style="margin-left: 20px;">3. Recipient claims locked funds</b>
      </div>

      <br />

      <div style={state.result ? 'display: none' : ''}>

        <h4>Recipient Email</h4>

        <input style={{ padding: '20px' }}  id="email" type="email" placeholder="jane@email.com" />

        <h4>Amount</h4>

        <div style="display: flex; flex-direction: row; align-items: center;">

          <select id="type" style="margin-right: 20px; padding: 20px;" onchange={actions.ontype}>
            <option value="dai">USD (Dai)</option>
            <option value="ether">Ether (ETH)</option>
          </select>

          <input style={{ padding: '20px' }} id="amount" type="number" placeholder="0.00" />
        </div>

        <br />

        <div onclick={e => document.getElementById('paygas').click()}
          style="cursor: pointer; display: none;">
          <input type="checkbox" id="paygas" checked="checked" /> Pay for recipient gas fee ({state.type === 'dai' ? '$0.04 USD' : 'Approx. $0.04 USD'})
        </div>

        <h4>Message</h4>

        <input style={{ padding: '20px' }} id="message" type="text" placeholder="Optional" />

        <div style="display: flex; flex-direction: row;">

        <div style="margin-right: 20px;">
          <h4>Enter a password</h4>

          <input style={{ padding: '20px' }}  id="password" type="password" placeholder="Password" />
        </div>

        <div>
          <h4>Re-type password</h4>

          <input style={{ padding: '20px' }}  id="repassword" type="password" placeholder="Password Again" />
        </div>

        </div>

        <br /><br />

        <div onclick={e => document.getElementById('terms').click()}
          style="cursor: pointer;">
          <input type="checkbox" id="terms" checked="checked" /> I agree to the <a href="/terms" target="_blank">Terms of Service</a>.
        </div>

        <div onclick={e => document.getElementById('legal').click()}
          style="cursor: pointer;">
          <input type="checkbox" id="legal" checked="checked" /> I agree this is alpha research software.
        </div>

        <br /><br />

        <button style={{ padding: '20px' }} onclick={actions.dropoff}>
          Send Funds
        </button>

      </div>

      {state.result ? (<div>
        <h3>{state.result}</h3>
        <p>{state.transactionHash ? (
          <a href={`https://etherscan.io/tx/${state.transactionHash}`} target="_blank">
            View it on Etherscan
          </a>
        ) : ''}</p>
      </div>) : ''}

      <br />

      {state.error ? (<div>

        <h2>Darn! There was a problem: </h2>
        <p>{state.error}</p>

        <button onclick={e => actions.change({ result: null, transactionHash: null, error: null })}>
          Try again?
        </button>
      </div>) : ''}
    </Container>
  );
}

// Not found page
const NotFound = () => (
  <div style={{ padding: '20%', 'padding-top': '100px' }}>
    <h1>NickPay</h1>
    <h3>Hmm... Page not found</h3>
  </div>
);

const Terms = () => (
  <div>
    <h1>Website Terms and Conditions of Use</h1>

    <h2>1. Terms</h2>

    <p>By accessing this Website, accessible from https://nickpay.com, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law. These Terms of Service has been created with the help of the <a href="https://termsofservicegenerator.net">Terms of Service Generator</a> and the <a href="https://privacy-policy-template.com">Privacy Policy Template</a>.</p>

    <h2>2. Use License</h2>

    <p>Permission is granted to temporarily download one copy of the materials on NickPay's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>

    <ul>
        <li>modify or copy the materials;</li>
        <li>use the materials for any commercial purpose or for any public display;</li>
        <li>attempt to reverse engineer any software contained on NickPay's Website;</li>
        <li>remove any copyright or other proprietary notations from the materials; or</li>
        <li>transferring the materials to another person or "mirror" the materials on any other server.</li>
    </ul>

    <p>This will let NickPay to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.</p>

    <h2>3. Disclaimer</h2>

    <p>All the materials on NickPay’s Website are provided "as is". NickPay makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, NickPay does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.</p>

    <h2>4. Limitations</h2>

    <p>NickPay or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on NickPay’s Website, even if NickPay or an authorize representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</p>

    <h2>5. Revisions and Errata</h2>

    <p>The materials appearing on NickPay’s Website may include technical, typographical, or photographic errors. NickPay will not promise that any of the materials in this Website are accurate, complete, or current. NickPay may change the materials contained on its Website at any time without notice. NickPay does not make any commitment to update the materials.</p>

    <h2>6. Links</h2>

    <p>NickPay has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by NickPay of the site. The use of any linked website is at the user’s own risk.</p>

    <h2>7. Site Terms of Use Modifications</h2>

    <p>NickPay may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>

    <h2>8. Your Privacy</h2>

    <p>Please read <a href="https://privacypolicygenerator.info/">our Privacy Policy</a>.</p>

    <h2>9. Governing Law</h2>

    <p>Any claim related to NickPay's Website shall be governed by the laws of Ontario, Canada without regards to its conflict of law provisions.</p>
  </div>
);

// routes for app
const Routes = () => (
  <Switch>
    <Route path="/" render={Dropoff} />
    <Route path="/pickup/:ipfsHash/:secret" render={Pickup} />
    <Route path="/terms" render={Terms} />
    <Route render={NotFound} />
  </Switch>
);

// main app
const main = app(
  state,
  actions,
  Routes,
  document.body,
);

// unsubscripe for routing
const unsubscribe = location.subscribe(main.location);

// main loading
main.load();

// change global style..
styled.injectGlobal`
  body {
    font-family: 'Open Sans', sans-serif;
  }
`;
